import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/Devfest2023/Devfest2023/src/layout/mdx.tsx";
import { TeamMembers } from "../../components/team/teamMembers";
export const metadata = {
  title: "L'Équipe"
};
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const SecondarySection = makeShortcode("SecondarySection");
const PrimarySection = makeShortcode("PrimarySection");
const layoutProps = {
  metadata,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SecondarySection mdxType="SecondarySection">
      <h2>{`Le DevFest Nantes est un événement organisé par le GDG Nantes.`}</h2>
      <p>{`Les Google Developers Groups (GDGs) sont des regroupements de personnes intéressées par les technologies et plateformes de Google : Android, Chrome, Google Apps, Angular, Cloud… partout dans le monde.`}</p>
      <p>{`La conférence est communautaire, 100 % à but non lucratif et l’équipe d’organisation n’est pas rémunérée.`}</p>
      <p>{`L’objectif du GDG Nantes, association loi 1901 créée en 2011, est d’organiser des événements de manière régulière :`}</p>
      <ul>
        <li parentName="ul">{`Des conférences sur les technologies Google, Web, Mobile, Cloud, Big Data…`}</li>
        <li parentName="ul">{`Des apéros ;) autour de sujets centraux afin de se rencontrer et boire un verre ensemble,`}</li>
        <li parentName="ul">{`Des codelabs pour apprendre concrètement et techniquement sur les technologies,`}</li>
        <li parentName="ul">{`Des concours (Hackathon, concours de code ou d’algorithmie…)`}</li>
        <li parentName="ul">{`Des retransmissions vidéo d'événements majeurs : conférences US, keynotes`}</li>
      </ul>
      <p>{`Pour rendre tout cela possible, une équipe de bénévoles sur-vitaminée s’active en coulisse.`}</p>
    </SecondarySection>
    <PrimarySection mdxType="PrimarySection">
      <h2>{`Core Team`}</h2>
      <TeamMembers mdxType="TeamMembers" />
    </PrimarySection>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      